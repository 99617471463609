.schedule-widget__widget {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
    font-size: 1.3em;
}

.schedule-widget__events {
    margin: 0 auto;
    border-radius: 5px;
    padding: 0;
}

.schedule-widget__events.schedule-widget__current {
    background: #46b1dbcc;
    overflow: hidden;
}
.schedule-widget__events.schedule-widget__current .event-item__name {
    color: white;
}
.schedule-widget__events .event-item__container {
    border-top: 2px solid #fff1;
}

.schedule-widget__events .event-item__container:first-of-type {
    border-top: none;
}
