.event-item__container {
    display: grid;
    margin: 10px;
    text-align: left;
    align-items: center;
    grid-auto-flow: column;
    /*justify-content: space-between;*/
    gap: 20px;
    grid-template-columns: 23% 23% auto;
    grid-template-areas: "time image content";
    min-height: 200px;
}

.event-item__container > * {
    padding: 10px;
}

.event-item__content-container {
    grid-template-rows: auto;
    grid-area: content;
}

.event-item__from {
    font-size: 1.4em;
    font-variant: small-caps;
    grid-area: time;
}

.event-item__link {
    display: flex;
    /*width: fit-content;*/
    align-items: center;
    justify-content: left;
    flex-direction: row;
    gap: 15px;
    margin: 0;
}

.event-item__link .fa {
    display: inline-block;
    vertical-align: middle;
    width:.8em;
    flex: 0;
}
.event-item__link .fa.fa-globe {
    margin-bottom: 2px;
}

.event-item__link a {
    text-decoration: none;
    color: #fff;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 2px;
    margin-left: 5px;
}

.event-item__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: image;
}

.event-item__image {
    max-width: 100%;
    max-height: 220px;
    float: left;
    margin: 20px;
}

.event-item__name {
    font-size: 1.5em;
    line-height: 1.5em;
    color: #83c2db;
    font-family: "Freesans Bold", "sans-serif";
    margin: 0;
}

.event-item__pronouns {
    font-size: .9em;
    opacity: 0.8;
    font-weight: lighter;
    font-style: italic;
    margin-top: 0;
}

.event-item__blurb:last-of-type {
    margin-bottom: 0;
}

@media (max-width: 649px) {
    .event-item__container {
        gap: 0;
        display: flex;
        flex-flow: column;
    }

    .event-item__image-container {
        justify-content: center;
    }

    .event-item__link {
        justify-content: center;
    }

    .event-item__from, .event-item__link, .event-item__name, .event-item__pronouns, .event-item__blurb {
        padding-left: 0;
        text-align: center;
    }
}
