.host-widget__widget {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
    font-size: 1.7em;
}

.host-widget__widget--right .host__container {
    text-align: right;
    margin-left: auto;
}

.host-widget__widget--right .host__twitch {
    flex-direction: row-reverse;
    margin-left: auto;
}