.default-page__page {
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    background-image: url("bg.jpg");
    background-position: center top;
    background-size: cover;
    background-attachment: fixed;
}

.default-page__container {
    width: 100%;
    box-sizing: border-box;
    max-width: 800px;
    margin: 0 auto;
}

.default-page__header-logo {
    width: 100%;
    max-width: 600px;
    margin-top: 50px;
    margin-left: -40px;
    margin-bottom: 0px;
    filter: drop-shadow(0.25rem 0.25rem 0.5rem rgba(3, 13, 24, 0.85));
}

.default-page__tag-line {
    color: #46B1DB;
    font-family: "Freesans Bold", "sans-serif";
    font-weight: bold;
    font-size: 2.55em;
    line-height: 1.2em;
    width: 100%;
    max-width: 600px;
    margin: 0 0;
}

.default-page__twitch-embed {
    margin-top: 50px;
    width: 800px;
    height: 450px
}

.default-page__intro {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /*background-color: rgba(255, 0, 0, .1);*/
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
}

.default-page__blurb {
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
}

.default-page__blurb p:first-child {
    margin-top: 0;
}

.default-page__blurb p:last-child {
    margin-bottom: 0;
}

.default-page__details {
    width: 325px;
    width: fit-content;
    padding-left: 30px;
    padding-top: 5px;
    border-left: #fff1 solid 2px;
}

.default-page__date {
    color: #46B1DB;
    font-size: 1.8em;
    font-family: "Freesans Bold", "sans-serif";
    margin: 0;
}

.default-page__time {
    margin: 0;
    color: #46B1DB;
    font-size: 1.25em;
    line-height: 1em;
}

.default-page__location {
    margin-top: 35px;
    font-size: 1.25em;
    line-height: 1.2em;
}

.default-page__location-label {
    display: inline-block;
    width: 120px;
}

.default-page__location-value {
    display: inline-block;
    font-family: "Freesans Bold", "sans-serif";
    color: #46B1DB;
}

.default-page__location-value > span {
    display: inline-block;
    color: white;
    font-family: "Freesans", "sans-serif";
    margin-left: 5px;
}

.default-page__button {
    color: white;
    background: #46B1DB;
    text-align: center;
    padding: 10px 10px;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 80px;
    display: inline-block;
    font-family: "Freesans Bold", "sans-serif";
    width: 100%;
    max-width: 300px;
}

.default-page__host-grid {
    display: flex;
    gap: 40px;
    margin-top: 40px;

    .default-page__staff {
        flex-grow: 1;
    }

    .default-page__hosts .host__container {
        width: auto;
    }
}

.default-page__staff,
.default-page__hosts,
.default-page__events {
    margin: 0 auto;
    background: rgba(3, 13, 24, 0.85);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    padding: 0 2em 15px;
    box-sizing: border-box;

    h1, h2 {
        margin: 0;
        text-align: center;
    }
    h2 {
        color: #46B1DB;
        margin-bottom: 0;
        padding-top: 0.5em;
        font-family: "Freesans Bold", "sans-serif";
    }
    .default-page__staff-member,
    .default-page__staff-container,
    .host__container,
    .event-item__container {
        border-top: 2px solid #fff1;
    }
}

.default-page__staff-member {
    display: grid;
    gap: 25px;
    grid-template-columns: 100px auto;
    grid-template-areas: "logo description";
    padding: 20px 0;
    align-items: center;

    img {
        grid-area: logo;
        width: 100%;
        border-radius: 50%;
    }

    > div {
        grid-area: description;

        h3 {
            margin: 0;
        }
        p {
            margin: 0;
        }
        .default-page__staff-link {
            display: flex;
            align-items: center;
            justify-content: left;
            flex-direction: row;
            gap: 10px;
            margin: 0;
            width: min-content;

            a {
                text-decoration: none;
                color: #fff;
                vertical-align: middle;
                display: inline-block;
                margin-bottom: 2px;
                margin-left: 5px;
            }
        }
    }
}

.default-page__disclaimer {
    color: #FFF;
    font-size: .9em;
    text-shadow: #111 0 0 5px;
    margin: 25px 0;
}

@media (max-width: 820px) {
    .default-page__container {
        padding: 0 30px;

    }

    .default-page__header-logo {
        margin-left: 0;
    }

    .default-page__twitch-embed {
        width: 90vw;
        height: 50vw
    }

    .default-page__intro {
        display: block;
    }

    .default-page__details {
        border-left: 0;
        padding-left: 0;
        margin-top: 20px;
        margin-bottom: 50px;
    }
}

@media (max-width: 820px) {
    .default-page__host-grid {
        flex-direction: column;
        position: relative;

        > * {
            width: 100%;
        }
    }
}

@media (max-width: 530px) {
    .default-page__events {
        grid-template-columns: auto;
    }

    .default-page__staff-member {
        display: block;
        text-align: center;

        img {
            width: 75%;
            margin: 10px;
        }

        div .default-page__staff-link {
            margin: 0 auto;
        }
    }
}
