.host__container {
    width: 600px;
    padding: 10px;
    text-align: left;
}

.host__content-container {
    grid-template-rows: auto;
}

.host__name {
    font-size: 1.5em;
    line-height: 1.5em;
    color: #83c2db;
    font-family: "Freesans Bold", "sans-serif";
    margin: 0;
    padding-left: 0;
}

.host__pronouns {
    margin: 0 0 10px 0;
    font-size: .9em;
    opacity: 0.8;
    font-weight: lighter;
    font-style: italic;
    padding-left: 0;
}


.host__twitch {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 15px;
    margin: 0;
}

.host__twitch .fa {
    vertical-align: middle;
}

.host__twitch a {
    text-decoration: none;
    color: #fff;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 2px;
    margin-left: 5px;
}
