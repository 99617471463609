@font-face {
    font-family: 'Freesans';
    src: url('../font/freesans.woff2') format('woff2'),
    url('../font/freesans.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'Freesans Bold';
    src: url('../font/freesans-bold.woff2') format('woff2'),
    url('../font/freesans-bold.woff') format('woff');
    font-weight: bold;
}

:root {
    font-size: 16px;
    width: 100%;
}

body {
    margin: 0;
    color: white;
    justify-content: center;
    font-family: "Freesans", "sans-serif";
    background: rgba(0, 0 , 0, 0);
    width: 100%;
    height: 100%;
}

a {
    color: #ED1C24;
}