.performance-widget__widget {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
    font-size: 1.7em;
}

.performance-widget__widget .event-item__from { display: none; }
.performance-widget__widget .event-item__blurb { display: none; }
.performance-widget__widget .event-item__container {
    grid-template-columns: 33% auto;
    grid-template-areas: "image content";
}
.performance-widget__widget--right .event-item__container {
    grid-template-columns: auto 33%;
    grid-template-areas: "content image";
    text-align: right;
}
.performance-widget__widget--right .event-item__link {
    flex-direction: row-reverse;
    justify-content: right;
    margin-left: auto;
}
